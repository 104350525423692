import { Fragment } from 'react'

import { MDBCard } from 'mdb-react-ui-kit'

import classes from '../assets/css/KredupayCardExpired.module.css'
import cardCancelled from '../assets/img/credit-card-cancelled.svg'

function KredupayCardExpired({
  productName,
  price,
  last4Digits,
  expiryMonth,
  cvc,
}) {
  return (
    <Fragment>
      <div className='text-center'>
        <MDBCard className='mb-3 p-4 text-muted'>
          <img
            src={cardCancelled}
            className={`${classes.cardImg} mb-3 mx-auto`}
            alt='card'
          />
          <p className={`${classes.small} text-muted mx-auto  mb-0`}>
            Diese Karte war bis zum {expiryMonth} gültig und ist bereits
            gekündigt.
          </p>
        </MDBCard>
      </div>
      <div className='d-flex justify-content-between mb-3 col'>
        <span className=''>{productName}</span>
        <span>{price.toFixed(2).replace('.', ',')} €</span>
      </div>
      {last4Digits !== null && expiryMonth !== null && (
        <div>
          <div className=' d-flex justify-content-between mb-2 col'>
            <span className='s-text'>Letzten 4 Ziffern</span>
            <span className='s-text'>{last4Digits}</span>
          </div>
          <div className=' d-flex justify-content-between mb-2 col'>
            <span className='s-text'>Gültigkeit</span>
            <span className='s-text'>{expiryMonth}</span>
          </div>
          <div className=' d-flex justify-content-between mb-2 col'>
            <span className='s-text'>CVC</span>
            <span className='s-text'>{cvc}</span>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default KredupayCardExpired
