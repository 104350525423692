import { useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { MDBCard, MDBBtn } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import userService from '../../services/userService'
import { ROUTES } from '../../data/constants'
import LoadingSpinner from '../../components/LoadingSpinner'
import PhoneNumberEditModal from '../../features/dashboard/personalInformation/components/PhoneNumberEditModal'

import classes from './DashboardPersonalInformation.module.css'

function DashboardPersonalInformation() {
  const [userData, setUserData] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showModalEditPhoneNumber, setShowModalEditPhoneNumber] = useState(false)
  const [shouldReloadComponent, setShouldReloadComponent] = useState(false)

  const { user } = useSelector((state) => state.auth)

  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    userService
      .getUserData(user.token)
      .then((data) => {
        setIsLoading(false)
        setUserData(data)
      })
      .catch((error) => {
        setIsLoading(false)
        navigate(ROUTES.ERROR_500)
      })
  }, [user.token, shouldReloadComponent])

  const reloadPersonalInformation = () => {
    setShouldReloadComponent(!shouldReloadComponent)    
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Persönliche Informationen</title>
        <meta name='description' content='KREDU - Persönliche Informationen' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      {isLoading && (
        <div className={classes.loading}>
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && (
        <div className='p-0 p-xl-5 mx-xl-auto container pb-5'>
          <div className='row'>
            <div className='offset-xl-2 col-12 col-xl-8'>
              <MDBCard className='p-3 mt-4 mt-sm-5 mt-lg-3 mx-3 mb-4'>
                <h4 className='fw-bold font-blue mb-3'>
                  Persönliche Informationen
                </h4>
                <div>
                  {userData && userData.firstName && userData.lastName && (
                    <div>
                      <p className={`${classes.small} mb-0`}>Name:</p>
                      <p className={`${classes.info} text-muted mb-3`}>
                        {(userData.salutation === 'Male' ? 'Herr' : 'Frau') +
                          ' ' +
                          userData.firstName +
                          ' ' +
                          userData.lastName}{' '}
                      </p>
                    </div>
                  )}
                  <p className={`${classes.small} mb-0`}>E-Mail:</p>{' '}
                  <p className='text-muted'> {userData.email}</p>
                  {userData && userData.birthdate && (
                    <div>
                      <p className={`${classes.small} mb-0`}>Geburtsdatum:</p>
                      <p className='mb-3 text-muted'>{userData.birthdate} </p>
                    </div>
                  )}
                  {userData && userData.mobilePhoneNumber && userData.mobilePhoneNumber !== '0' && (
                    <div>
                      <p className={`${classes.small} mb-0`}>Mobilnummer:</p>
                      <div className='text-muted mb-3'>
                        {userData.mobilePhoneNumber}{' '}
                        <MDBBtn
                          onClick={() => setShowModalEditPhoneNumber(true)}
                          className=' d-block p-0 font-blue mb-1 text-lowercase text-decoration-underline'
                          color='tertiary'
                          rippleColor='light'
                        >
                          bearbeiten
                        </MDBBtn>{' '}
                        <PhoneNumberEditModal
                          show={showModalEditPhoneNumber}
                          onHide={() => setShowModalEditPhoneNumber(false)}
                          reload={reloadPersonalInformation}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* <Link to='' className={`${classes.fontBlue}`}>
                  bearbeiten
                </Link> */}
              </MDBCard>
            </div>
            {userData &&
              (userData.nationality ||
                userData.occupation ||
                userData.income) && (
                <div className='offset-xl-2 col-12 col-xl-8'>
                  <MDBCard className='p-3 mx-3 mb-4'>
                    <h4 className='fw-bold font-blue mb-3'>
                      Zusätzliche Informationen
                    </h4>
                    <div>
                      <p className={`${classes.small} mb-0`}>
                        Staatsbürgerschaft:
                      </p>
                      {userData && userData.nationality && (
                        <p className='text-muted mb-3'>
                          {' '}
                          {userData.nationality}
                        </p>
                      )}
                      <p className={`${classes.small} mb-0`}>
                        Beschäftigungsstatus:
                      </p>
                      {userData && userData.occupation && (
                        <p className='text-muted mb-3'>
                          {' '}
                          {userData.occupation}
                        </p>
                      )}
                      <p className={`${classes.small} mb-0`}>Einkommen:</p>
                      {userData && userData.income && (
                        <p className='mb-3 text-muted'> {userData.income} €</p>
                      )}
                    </div>
                    {/* <Link to='' className={`${classes.fontBlue}`}>
                  bearbeiten
                </Link> */}
                  </MDBCard>
                </div>
              )}
            {userData &&
              userData.streetName &&
              userData.houseNumber &&
              userData.postalCode &&
              userData.city &&
              userData.country && (
                <div className='offset-xl-2 col-12 col-xl-8'>
                  <MDBCard className='p-3 mx-3'>
                    <h4 className='fw-bold font-blue mb-3'>Adresse</h4>
                    <p className='text-muted'>
                      {userData.streetName + ' ' + userData.houseNumber}
                      <br />
                      {userData.postalCode}
                      <br />
                      {userData.city}
                      <br />
                      {userData.country}
                    </p>
                    {/* <Link to='' className={`${classes.fontBlue}`}>
                  bearbeiten
                </Link> */}
                  </MDBCard>
                </div>
              )}
          </div>
          {/* <DashboardPersonalInformationLoadingError /> */}
        </div>
      )}
    </Fragment>
  )
}

export default DashboardPersonalInformation
