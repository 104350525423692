import { useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { MDBBtn } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../data/constants'
import canceled from '../../assets/img/payment-canceled-icon.svg'

function StripePaymentInterrupted() {
  const navigate = useNavigate()  

  useEffect(() => {    
    //if (!document.referrer.startsWith('https://checkout.stripe.com') || !document.referrer.includes('klarna.com')) {
      //navigate('/')
    //}    
  }, [])

  const handleButtonClick = () => {
    navigate(ROUTES.CART)
  }

  return (
    <Fragment>
      <Helmet>
          <title>KREDU - Zahlung abgebrochen</title>
          <meta name="description" content="KREDU - Zahlung abgebrochen" />
          <meta name="robots" content="noindex,nofollow" /> 
        </Helmet>
      <div className='text-center vh-100 pt-lg-5 container'>
        <div className='container-heigh row'>
          <div className='text-center mx-auto col-12 col-sm-10 col-md-6'>
            <div className='text-center box-width'>
              <img
                className='mb-2 mb-md-3 img-fluid'
                src={canceled}
                alt='success'
              ></img>
              <h2 className='mb-3 fw-bold'> Zahlung abgebrochen</h2>
              <p className='pt-4 pt-md-0 text-center ps-0 mb-4 fw-normal registration-box-info'>
                Du hast deine Zahlung über Klarna erfolgreich
                abgebrochen. Wenn du es noch einmal mit der Zahlung versuchen oder
                deine Bestellung ändern möchtest, klicke auf den Button unten,
                um zurück zum Warenkorb zu gelangen.
              </p>

              <MDBBtn
                type='button'
                onClick={handleButtonClick}
                className='btn-yellow mb-5 mb-md-0 mt-auto mt-md-3 waves-effect'
              >
                Zurück zum Warenkorb
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default StripePaymentInterrupted
