import { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { signOut } from '../features/auth/authSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightToBracket } from '@fortawesome/pro-light-svg-icons'
import { faRightFromBracket } from '@fortawesome/pro-light-svg-icons'  
import { ROUTES } from '../data/constants'

function NavigationIdentityDashboard({closeNavbarHandler}) {
  const { user } = useSelector((state) => state.auth)  

    const dispatch = useDispatch()    
    const navigate = useNavigate()

    useEffect(() => {
    }, [user, dispatch])

    const signOutLinkOnClickHandler = (e) => {
        dispatch(signOut())
        navigate('/')
        closeNavbarHandler()
    }

  return (
    <Fragment>
    {user !== null ? (
            <Link to='/' className='me-3 nav-link d-none d-md-block' onClick={signOutLinkOnClickHandler}>
              <FontAwesomeIcon
                icon={faRightFromBracket}
                fontSize='17px'
                className='me-1 font-blue'
              />
              Abmelden
            </Link>
      ) : (
          <Link to={ROUTES.SIGN_IN} className='me-xl-3 nav-link d-none d-md-block'>
            <FontAwesomeIcon
              icon={faRightToBracket}
              fontSize='17px'
              className='me-1 font-blue'
            />
            Anmelden
          </Link>
      )}
    </Fragment>
  )
}

export default NavigationIdentityDashboard