import { MDBCard, MDBBtn } from 'mdb-react-ui-kit'
import { useDispatch } from 'react-redux'
import { removeCartItem } from '../../cart/redux/cartSlice'

import classes from '../assets/css/CartItemOutOfStock.module.css'

function CartItemOutOfStock({
  productId,
  productType,
  productName,
  slug,
  mode,
}) {
  const dispatch = useDispatch()

  function removeItem() {
    dispatch(removeCartItem(productId))
  }
  return (
    <MDBCard className={`${classes.shoppingCart} ${classes.outOfStock} mb-3`}>
      <div className='row'>
        <div className='col-6 col-lg-5 col-xl-3'>
          <div className='row d-md-flex align-items-center'>
            <div className='col order-2 order-sm-1 col ps-2 ps-lg-3 pe-0'>
              <img
                src={`${process.env.PUBLIC_URL}/img/products/${slug}.svg`}
                alt='card'
                className='px-0 pt-md-1 ms-1 ms-md-0'
              ></img>
            </div>
            <div className='col order-1 order-sm-2'>
              <p className='fw-bold mb-0 ms-2 ms-md-0 mt-1 mt-md-0 text-muted'>
                {productName}
              </p>
            </div>
          </div>
        </div>
        <div className='col-3 col-xl-5 d-flex align-items-center justify-content-center '>
          <p className='text-muted'>
            Dieser Artikel ist zur Zeit nicht auf Lager
          </p>
        </div>
        <div className='col-2 col-lg-3 d-flex text-center align-items-center justify-content-center pe-lg-0 pt-2 pt-lg-0'>
          <p className='text-center fs-2'>-</p>
        </div>
        <div className='col-1 text-end'>
          <MDBBtn
            className='btn-close mt-3 me-3'
            color='none'
            aria-label='Close'
            onClick={removeItem}
          />
        </div>
      </div>
    </MDBCard>
  )
}

export default CartItemOutOfStock
