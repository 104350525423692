import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  MDBNavbar,  
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,  
  MDBCollapse,
  MDBBtn,  
} from 'mdb-react-ui-kit'

import { Link } from 'react-router-dom'

import classes from './DashboardNavigationDesktopOnly.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-light-svg-icons'
import cartIcon from '../assets/img/cart-icon.svg'

import kreduLogo from '../assets/img/Kredu-logo.svg'
import purchaseIcon from '../assets/img/purchase-history-icon.svg'
import purchaseIconBlue from '../assets/img/purchase-history-icon-blue.svg'
import accountIcon from '../assets/img/my-account-icon.svg'
import accountIconBlue from '../assets/img/my-account-icon-blue.svg'

import NavigationIdentity from './NavigationIdentity'
import NavigationIdentityDashboard from './NavigationIdentityDashboard'

import { ROUTES } from '../data/constants'

function DashboardNavigationDesktopOnly() {
  const [showNav, setShowNav] = useState(false)
  const [cartItemsTotalQty, setCartItemsTotalQuantity] = useState(0) 

  const { cartItems } = useSelector((state) => state.cart)

  useEffect(() => {
    const totalCartItemsQuantity = cartItems.reduce(
      (partialSum, x) => partialSum + x.quantity,
      0
    )
    setCartItemsTotalQuantity(totalCartItemsQuantity)
  }, [cartItems]) 

  const handleNavbarItemClick = (e) => {
    setShowNav(false)
  }

  return (
    <div  className='px-0 container-fluid d-none d-md-block'>
    <MDBNavbar
      className={`fixed-top bg-white ${classes.navbarDashboard}`}
      id='navbarDashboard' 
    >
      
        <Link to='/' className={classes.kreduLogo}
          onClick={handleNavbarItemClick}>
          <img src={kreduLogo} alt='Kredu' />
        </Link>
        <div className='d-none d-md-flex ms-auto'>
          <Link className='me-5' to={ROUTES.DASHBOARD + '/' + ROUTES.DASHBOARD_ORDERS} onClick={handleNavbarItemClick}>
            <img src={purchaseIcon} className='me-2 d-none' alt='bestellungen' />
            <img src={purchaseIconBlue} className='me-2' alt='bestellungen' />
            <span className='font-blue'> Bestellungen </span>
          </Link>
          <Link className='me-4' to={ROUTES.DASHBOARD + '/' + ROUTES.DASHBOARD_USER_PROFILE} onClick={handleNavbarItemClick}>
            <img src={accountIcon} className='me-2 d-none' alt='konto' />
            <img
              src={accountIconBlue}
              alt='konto'
              className='me-2'
            />
            <span className='font-blue'>Mein Profil</span>
          </Link>
        </div>
        <NavigationIdentityDashboard />
        <Link
          to={ROUTES.CART}
          className={`${classes.badgeLink} nav-link ms-auto ms-md-2 ms-lg-4 pe-1 pe-md-3`}
          onClick={handleNavbarItemClick}
        >
          <span
            className={`${classes.badge} ${
              cartItemsTotalQty > 0 ? '' : 'd-none'
            }`}
          >
            {cartItemsTotalQty}
          </span>
          <img
            src={cartIcon}
            width='23px'
            className={classes.cartImg}
            alt='warenkorb'
          ></img>
        </Link>
        <MDBNavbarToggler
          type='button'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNav(!showNav)}
          className='me-md-3 me-1'
        >
          <FontAwesomeIcon icon={faBars} fontSize='24px' className={classes.fontBlue}/>
          
        </MDBNavbarToggler>
        <MDBCollapse navbar open={showNav}>
        <MDBNavbarNav className='ms-auto w-auto'>
          <MDBNavbarItem>
            <Link
              aria-current='page'
              to={ROUTES.ALL_PRODUCTS}
              className='me-xl-3 nav-link'
              onClick={handleNavbarItemClick}
            >
              Alle Gutscheine
            </Link>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <Link
            to='/#soFunktioniert'
              aria-current='page'
              className='me-xl-3 nav-link'
              onClick={handleNavbarItemClick}
            >
              So funktioniert’s
            </Link>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <Link to={ROUTES.FAQ} className='me-xl-3 nav-link' onClick={handleNavbarItemClick}>
              FAQ
            </Link>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <Link to={ROUTES.CONTACT} className='nav-link me-xl-3' onClick={handleNavbarItemClick}>
              Kontakt
            </Link>
          </MDBNavbarItem>
          <NavigationIdentity />
          <Link to={ROUTES.ALL_PRODUCTS} onClick={handleNavbarItemClick}>
            <MDBBtn
              className={`${classes.btnYellow} mx-xl-3 mb-3 mt-4 `}
            >
              Jetzt kaufen
            </MDBBtn>
          </Link>

          <MDBNavbarItem>
            <Link
              to={ROUTES.CART}
              className={`${classes.badgeLink} ms-xl-3 me-xl-4 nav-link`}
              onClick={handleNavbarItemClick}
            >
              <span
                className={`${classes.badge} ${
                  cartItemsTotalQty > 0 ? '' : 'd-none'
                }`}
              >
                {cartItemsTotalQty}
              </span>
              <img
                src={cartIcon}
                className={classes.cartImg}
                alt='warenkorb'
              ></img>
            </Link>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBCollapse>
      
    </MDBNavbar>
    </div>
  )
}

export default DashboardNavigationDesktopOnly
