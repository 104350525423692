import { useState, Fragment } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import userService from '../../services/userService'
import { MDBInput, MDBSelect, MDBBtn, MDBSpinner } from 'mdb-react-ui-kit'
import FormError from '../../components/FormError'
import { ROUTES } from '../../data/constants'
import { PAYMENT_METHODS } from '../../data/constants'

import classes from './RegistrationAdditionalInformation.module.css'

function RegistrationAdditionalInformation() {
  const [nationality, setNationality] = useState('')
  const [occupation, setOccupation] = useState('')
  const [netIncome, setNetIncome] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { user } = useSelector((state) => state.auth)
  const { paymentMethod } = useSelector((state) => state.cart)

  const navigate = useNavigate()

  const handleNationalityChange = (e) => {
    setNationality(e.value)
  }

  const handleOccupationChange = (e) => {
    setOccupation(e.value)
  }

  const handleNetIncomeChange = (e) => {
    setNetIncome(e.target.value.toString())
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    setIsError(false)
    setErrorMessage('')
    setIsLoading(true)

    const personalData = {
      nationality: nationality,
      occupation: occupation,
      netIncome: netIncome,
    }

    userService
      .registerAdditionalPersonalData(user.token, paymentMethod, personalData)
      .then((response) => {
        navigate(
          ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_BANK_VERIFICATION
        )
      })
      .catch((error) => {
        setIsError(true)
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Zusätzliche Informationen</title>
        <meta name='description' content='KREDU - Zusätzliche Informationen' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='vh-100 container'>
        <div
          className={`d-md-inline-block ${classes.registrationFormBox} mx-auto me-lg-0 registration-form-box ms-lg-3`}
        >
          <h2 className='mb-3 d-none d-md-block'>Zusätzliche Informationen</h2>
          <p className='pt-4 pt-md-0 text-start ps-3 ps-md-0 mb-5 fw-normal'>
            Wir benötigen zusätzlich folgende Informationen:
          </p>
          <form onSubmit={handleFormSubmit} className='form-box px-3 px-md-0'>
            <MDBSelect
              id='nationality'
              name='nationality'
              required
              preventFirstSelection
              label='Staatsbürgerschaft'
              data={[
                { value: 'Deutsch', text: 'Deutsch' },
                { value: 'Ägyptisch', text: 'Ägyptisch' },
                { value: 'Äquatorialguineisch', text: 'Äquatorialguineisch' },
                { value: 'Äthiopisch', text: 'Äthiopisch' },
                { value: 'Österreichisch', text: 'Österreichisch' },
                { value: 'Afghanisch', text: 'Afghanisch' },
                { value: 'Albanisch', text: 'Albanisch' },
                { value: 'Algerisch', text: 'Algerisch' },
                {
                  value: 'Amerikanisch (Überseeins.)',
                  text: 'Amerikanisch (Überseeins.)',
                },
                { value: 'Amerikanisch (Guam)', text: 'Amerikanisch (Guam)' },
                {
                  value: 'Amerikanisch (Jungfernins.)',
                  text: 'Amerikanisch (Jungferninsel)',
                },
                {
                  value: 'Amerikanisch (N. Marian. In.)',
                  text: 'Amerikanisch (N. Marian. In.)',
                },
                {
                  value: 'Amerikanisch (Ozeanien)',
                  text: 'Amerikanisch (Ozeanien)',
                },
                { value: 'Amerikanisch (Samoa)', text: 'Amerikanisch (Samoa)' },
                { value: 'Amerikanisch (USA)', text: 'Amerikanisch (USA)' },
                { value: 'Andorranisch', text: 'Andorranisch' },
                { value: 'Angolanisch', text: 'Angolanisch' },
                { value: 'Antiguanisch', text: 'Antiguanisch' },
                { value: 'Argentinisch', text: 'Argentinisch' },
                { value: 'Armenisch', text: 'Armenisch' },
                { value: 'Aserbaidschanisch', text: 'Aserbaidschanisch' },
                {
                  value: 'Australisch (Australien)',
                  text: 'Australisch (Australien)',
                },
                {
                  value: 'Australisch (Heard-McDon.I.)',
                  text: 'Australisch (Heard-McDon.Insel.)',
                },
                {
                  value: 'Australisch (Kokos-Inseln)',
                  text: 'Australisch (Kokos-Inseln)',
                },
                {
                  value: 'Australisch (Norfolk-Insel)',
                  text: 'Australisch (Norfolk-Insel)',
                },
                {
                  value: 'Australisch (Ozeanien)',
                  text: 'Australisch (Ozeanien)',
                },
                {
                  value: 'Australisch (Weihnachtsin.)',
                  text: 'Australisch (Weihnachtsinseln)',
                },
                { value: 'Bahamaisch', text: 'Bahamaisch' },
                { value: 'Bahrainisch', text: 'Bahrainisch' },
                { value: 'Bangladeschisch', text: 'Bangladeschisch' },
                { value: 'Barbadisch', text: 'Barbadisch' },
                { value: 'Belgisch', text: 'Belgisch' },
                { value: 'Belizisch', text: 'Belizisch' },
                { value: 'Beninisch', text: 'Beninisch' },
                { value: 'Bhutanisch', text: 'Bhutanisch' },
                { value: 'Bolivianisch', text: 'Bolivianisch' },
                {
                  value: 'Bosnisch-Herzegowinisch',
                  text: 'Bosnisch-Herzegowinisch',
                },
                { value: 'Botsuanisch', text: 'Botsuanisch' },
                { value: 'Brasilianisch', text: 'Brasilianisch' },
                { value: 'Britisch (Anguilla)', text: 'Britisch (Anguilla)' },
                { value: 'Britisch (Bermuda)', text: 'Britisch (Bermuda)' },
                {
                  value: 'Britisch (Falklandinseln)',
                  text: 'Britisch (Falklandinseln)',
                },
                { value: 'Britisch (Gibraltar)', text: 'Britisch (Gibraltar)' },
                {
                  value: 'Britisch (Großbritannien)',
                  text: 'Britisch (Großbritannien)',
                },
                { value: 'Britisch (Guernsey)', text: 'Britisch (Guernsey)' },
                { value: 'Britisch (Insel Man)', text: 'Britisch (Insel Man)' },
                { value: 'Britisch (Jersey)', text: 'Britisch (Jersey)' },
                {
                  value: 'Britisch (Jungferninseln)',
                  text: 'Britisch (Jungferninseln)',
                },
                {
                  value: 'Britisch (Kaimaninseln)',
                  text: 'Britisch (Kaimaninseln)',
                },
                {
                  value: 'Britisch (Montserrat)',
                  text: 'Britisch (Montserrat)',
                },
                {
                  value: 'Britisch (Pitcairninseln)',
                  text: 'Britisch (Pitcairninseln)',
                },
                {
                  value: 'Britisch (Südgeorg./Sandw.)',
                  text: 'Britisch (Südgeorg./Sandwich)',
                },
                {
                  value: 'Britisch (St. Helena)',
                  text: 'Britisch (St. Helena)',
                },
                {
                  value: 'Britisch (Territ.-Ind.Oze.)',
                  text: 'Britisch (Territ.-Ind.Oze.)',
                },
                {
                  value: 'Britisch (Turks- Caicosin.)',
                  text: 'Britisch (Turks- Caicosin.)',
                },
                { value: 'Bruneiisch', text: 'Bruneiisch' },
                { value: 'Bulgarisch', text: 'Bulgarisch' },
                { value: 'Burkinisch', text: 'Burkinisch' },
                { value: 'Burundisch', text: 'Burundisch' },
                { value: 'Chilenisch', text: 'Chilenisch' },
                { value: 'Chinesisch (China)', text: 'Chinesisch (China)' },
                { value: 'Chinesisch (Taiwan)', text: 'Chinesisch (Taiwan)' },
                { value: 'Costaricanisch', text: 'Costaricanisch' },
                { value: 'Dänisch (Dänemark)', text: 'Dänisch (Dänemark)' },
                { value: 'Dänisch (Färöer)', text: 'Dänisch (Färöer)' },
                { value: 'Dänisch (Grönland)', text: 'Dänisch (Grönland)' },
                { value: 'Deutsch', text: 'Deutsch' },
                {
                  value: 'Dominicanisch (Dominica)',
                  text: 'Dominicanisch (Dominica)',
                },
                {
                  value: 'Dominikanisch (Dom. Rep.)',
                  text: 'Dominikanisch (Dominikanische Republik)',
                },
                { value: 'Dschibutisch', text: 'Dschibutisch' },
                { value: 'Ecuadorianisch', text: 'Ecuadorianisch' },
                { value: 'Eritreisch', text: 'Eritreisch' },
                { value: 'Estnisch', text: 'Estnisch' },
                { value: 'Fidschianisch', text: 'Fidschianisch' },
                { value: 'Finnisch', text: 'Finnisch' },
                {
                  value: 'Französisch (Frankreich)',
                  text: 'Französisch (Frankreich)',
                },
                {
                  value: 'Französisch (Mayotte)',
                  text: 'Französisch (Mayotte)',
                },
                {
                  value: 'Französisch (Neukaledonien)',
                  text: 'Französisch (Neukaledonien)',
                },
                {
                  value: 'Französisch (Polynesien)',
                  text: 'Französisch (Polynesien)',
                },
                {
                  value: 'Französisch (Südgebiete)',
                  text: 'Französisch (Südgebiete)',
                },
                {
                  value: 'Französisch (St. Pierre/Mi.)',
                  text: 'Französisch (St. Pierre/Mi.)',
                },
                {
                  value: 'Französisch (Wallis/Futuna)',
                  text: 'Französisch (Wallis/Futuna)',
                },
                { value: 'Gabunisch', text: 'Gabunisch' },
                { value: 'Gambisch', text: 'Gambisch' },
                { value: 'Georgisch', text: 'Georgisch' },
                { value: 'Ghanaisch', text: 'Ghanaisch' },
                { value: 'Grenadisch', text: 'Grenadisch' },
                { value: 'Griechisch', text: 'Griechisch' },
                { value: 'Guatemaltekisch', text: 'Guatemaltekisch' },
                { value: 'Guinea-Bissauisch', text: 'Guinea-Bissauisch' },
                { value: 'Guineisch', text: 'Guineisch' },
                { value: 'Guyanisch', text: 'Guyanisch' },
                { value: 'Haitianisch', text: 'Haitianisch' },
                { value: 'Honduranisch', text: 'Honduranisch' },
                { value: 'Indisch', text: 'Indisch' },
                { value: 'Indonesisch', text: 'Indonesisch' },
                { value: 'Irakisch', text: 'Irakisch' },
                { value: 'Iranisch', text: 'Iranisch' },
                { value: 'Irisch', text: 'Irisch' },
                { value: 'Isländisch', text: 'Isländisch' },
                { value: 'Israelisch', text: 'Israelisch' },
                { value: 'Italienisch', text: 'Italienisch' },
                { value: 'Ivorisch', text: 'Ivorisch' },
                { value: 'Jamaikanisch', text: 'Jamaikanisch' },
                { value: 'Japanisch', text: 'Japanisch' },
                { value: 'Jemenitisch', text: 'Jemenitisch' },
                { value: 'Jordanisch', text: 'Jordanisch' },
                { value: 'Kambodschanisch', text: 'Kambodschanisch' },
                { value: 'Kamerunisch', text: 'Kamerunisch' },
                { value: 'Kanadisch', text: 'Kanadisch' },
                { value: 'Kapverdisch', text: 'Kapverdisch' },
                { value: 'Kasachisch', text: 'Kasachisch' },
                { value: 'Katarisch', text: 'Katarisch' },
                { value: 'Kenianisch', text: 'Kenianisch' },
                { value: 'Kirgisisch', text: 'Kirgisisch' },
                { value: 'Kiribatisch', text: 'Kiribatisch' },
                { value: 'Kolumbianisch', text: 'Kolumbianisch' },
                { value: 'Komorisch', text: 'Komorisch' },
                {
                  value: 'Kongolesisch (Kongo Dem. R.)',
                  text: 'Kongolesisch (Demokratische Repoblik Kongo)',
                },
                {
                  value: 'Kongolesisch (Kongo Rep.)',
                  text: 'Kongolesisch (Republik Kongo)',
                },
                {
                  value: 'Koreanisch (Korea Dem. VR)',
                  text: 'Koreanisch (Demokratische Volksrepublik Korea)',
                },
                {
                  value: 'Koreanisch (Korea Rep.)',
                  text: 'Koreanisch (Republik Korea)',
                },
                { value: 'Kosovarisch', text: 'Kosovarisch' },
                { value: 'Kroatisch', text: 'Kroatisch' },
                { value: 'Kubanisch', text: 'Kubanisch' },
                { value: 'Kuwaitisch', text: 'Kuwaitisch' },
                { value: 'Laotisch', text: 'Laotisch' },
                { value: 'Lesothisch', text: 'Lesothisch' },
                { value: 'Lettisch', text: 'Lettisch' },
                { value: 'Libanesisch', text: 'Libanesisch' },
                { value: 'Liberianisch', text: 'Liberianisch' },
                { value: 'Liechtensteinisch', text: 'Liechtensteinisch' },
                { value: 'Litauisch', text: 'Litauisch' },
                { value: 'Lucianisch', text: 'Lucianisch' },
                { value: 'Luxemburgisch', text: 'Luxemburgisch' },
                { value: 'Lybisch', text: 'Lybisch' },
                { value: 'Madagassisch', text: 'Madagassisch' },
                { value: 'Malawisch', text: 'Malawisch' },
                { value: 'Malaysisch', text: 'Malaysisch' },
                { value: 'Maledivisch', text: 'Maledivisch' },
                { value: 'Malisch', text: 'Malisch' },
                { value: 'Maltesisch', text: 'Maltesisch' },
                { value: 'Marokkanisch', text: 'Marokkanisch' },
                { value: 'Marshallisch', text: 'Marshallisch' },
                { value: 'Mauretanisch', text: 'Mauretanisch' },
                { value: 'Mauritisch', text: 'Mauritisch' },
                { value: 'Mazedonisch', text: 'Mazedonisch' },
                { value: 'Mexikanisch', text: 'Mexikanisch' },
                { value: 'Mikronesisch', text: 'Mikronesisch' },
                { value: 'Moldauisch', text: 'Moldauisch' },
                { value: 'Mongolisch', text: 'Mongolisch' },
                { value: 'Montenegrinisch', text: 'Montenegrinisch' },
                { value: 'Mosambikanisch', text: 'Mosambikanisch' },
                { value: 'Myanmarisch', text: 'Myanmarisch' },
                { value: 'Namibisch', text: 'Namibisch' },
                { value: 'Nauruisch', text: 'Nauruisch' },
                { value: 'Nepalesisch', text: 'Nepalesisch' },
                {
                  value: 'Neuseeländisch (Cook Isla.)',
                  text: 'Neuseeländisch (Cook Island)',
                },
                {
                  value: 'Neuseeländisch (Neuseeland)',
                  text: 'Neuseeländisch (Neuseeland)',
                },
                {
                  value: 'Neuseeländisch (Niue)',
                  text: 'Neuseeländisch (Niue)',
                },
                {
                  value: 'Neuseeländisch (Ozeanien)',
                  text: 'Neuseeländisch (Ozeanien)',
                },
                {
                  value: 'Neuseeländisch (Tokelau-I.)',
                  text: 'Neuseeländisch (Tokelau-Inseln)',
                },
                { value: 'Nicaraguanisch', text: 'Nicaraguanisch' },
                {
                  value: 'Niederländ. (Sint Maarten)',
                  text: 'Niederländ. (Sint Maarten)',
                },
                {
                  value: 'Niederländisch (Aruba)',
                  text: 'Niederländisch (Aruba)',
                },
                {
                  value: 'Niederländisch (Bonaire)',
                  text: 'Niederländisch (Bonaire)',
                },
                {
                  value: 'Niederländisch (Curacao)',
                  text: 'Niederländisch (Curacao)',
                },
                {
                  value: 'Niederländisch (Niederl.)',
                  text: 'Niederländisch (Niederl.)',
                },
                { value: 'Nigerianisch', text: 'Nigerianisch' },
                { value: 'Nigrisch', text: 'Nigrisch' },
                {
                  value: 'Norwegisch (Bouvet Insel)',
                  text: 'Norwegisch (Bouvet Insel)',
                },
                {
                  value: 'Norwegisch (Norwegen)',
                  text: 'Norwegisch (Norwegen)',
                },
                { value: 'Omanisch', text: 'Omanisch' },
                { value: 'Osttimorisch', text: 'Osttimorisch' },
                { value: 'Pakistanisch', text: 'Pakistanisch' },
                { value: 'Palästinensisch', text: 'Palästinensisch' },
                { value: 'Palauisch', text: 'Palauisch' },
                { value: 'Panamaisch', text: 'Panamaisch' },
                { value: 'Papua-neuguineisch', text: 'Papua-neuguineisch' },
                { value: 'Paraguyaisch', text: 'Paraguyaisch' },
                { value: 'Peruanisch', text: 'Peruanisch' },
                { value: 'Philippinisch', text: 'Philippinisch' },
                { value: 'Polnisch', text: 'Polnisch' },
                { value: 'Portugiesisch', text: 'Portugiesisch' },
                { value: 'Ruandisch', text: 'Ruandisch' },
                { value: 'Rumänisch', text: 'Rumänisch' },
                { value: 'Russisch', text: 'Russisch' },
                { value: 'Südafrikanisch', text: 'Südafrikanisch' },
                { value: 'Südsudanesisch', text: 'Südsudanesisch' },
                { value: 'Salomonisch', text: 'Salomonisch' },
                { value: 'Salvadorianisch', text: 'Salvadorianisch' },
                { value: 'Sambisch', text: 'Sambisch' },
                { value: 'Samoanisch', text: 'Samoanisch' },
                { value: 'San-marinesisch', text: 'San-marinesisch' },
                { value: 'Santomeisch', text: 'Santomeisch' },
                { value: 'Saudiarabisch', text: 'Saudiarabisch' },
                { value: 'Schwedisch', text: 'Schwedisch' },
                { value: 'Schweizerisch', text: 'Schweizerisch' },
                { value: 'Senegalesisch', text: 'Senegalesisch' },
                { value: 'Serbisch', text: 'Serbisch' },
                {
                  value: 'Serbisch-montenegrinisch',
                  text: 'Serbisch-Montenegrinisch',
                },
                { value: 'Seychellisch', text: 'Seychellisch' },
                { value: 'Sierraleonisch', text: 'Sierraleonisch' },
                { value: 'Simbabwisch', text: 'Simbabwisch' },
                { value: 'Singapurisch', text: 'Singapurisch' },
                { value: 'Slowakisch', text: 'Slowakisch' },
                { value: 'Slowenisch', text: 'Slowenisch' },
                { value: 'Somalisch', text: 'Somalisch' },
                { value: 'Spanisch (Ceuta)', text: 'Spanisch (Ceuta)' },
                {
                  value: 'Spanisch (Kanar. Inseln)',
                  text: 'Spanisch (Kanarische Inseln)',
                },
                { value: 'Spanisch (Melilla)', text: 'Spanisch (Melilla)' },
                { value: 'Spanisch (Spanien)', text: 'Spanisch (Spanien)' },
                { value: 'Srilankisch', text: 'Srilankisch' },
                { value: 'Sudanesich', text: 'Sudanesich' },
                { value: 'Surinamisch', text: 'Surinamisch' },
                { value: 'Swasiländisch', text: 'Swasiländisch' },
                { value: 'Syrisch', text: 'Syrisch' },
                { value: 'Türkisch', text: 'Türkisch' },
                { value: 'Tadschikisch', text: 'Tadschikisch' },
                { value: 'Tansanisch', text: 'Tansanisch' },
                { value: 'Thailändisch', text: 'Thailändisch' },
                { value: 'Togoisch', text: 'Togoisch' },
                { value: 'Tongaisch', text: 'Tongaisch' },
                { value: 'Tschadisch', text: 'Tschadisch' },
                { value: 'Tschechisch', text: 'Tschechisch' },
                { value: 'Tunesisch', text: 'Tunesisch' },
                { value: 'Turkmenisch', text: 'Turkmenisch' },
                { value: 'Tuvaluisch', text: 'Tuvaluisch' },
                { value: 'Ugandisch', text: 'Ugandisch' },
                { value: 'Ukrainisch', text: 'Ukrainisch' },
                { value: 'Ungarisch', text: 'Ungarisch' },
                { value: 'Uruguayisch', text: 'Uruguayisch' },
                { value: 'Usbekisch', text: 'Usbekisch' },
                { value: 'Vanuatuisch', text: 'Vanuatuisch' },
                { value: 'Vatikanisch', text: 'Vatikanisch' },
                { value: 'Venezolanisch', text: 'Venezolanisch' },
                { value: 'Vietnamesisch', text: 'Vietnamesisch' },
                { value: 'Vincentisch', text: 'Vincentisch' },
                { value: 'Weißrussisch', text: 'Weißrussisch' },
                { value: 'Zentralafrikanisch', text: 'Zentralafrikanisch' },
                { value: 'Zyprisch', text: 'Zyprisch' },
                { value: 'aus Hongkong', text: 'Hongkong' },
                {
                  value: 'aus den Ver. Arab. Emiraten',
                  text: 'Vereinigte Arabische Emirate (UAE)',
                },
                { value: 'aus der Antarktis', text: 'Antarktis' },
                { value: 'von Macau', text: 'Macau' },
                {
                  value: 'von St. Kitts und Nevis',
                  text: 'St. Kitts und Nevis',
                },
                {
                  value: 'von Trinidad u. Tobago',
                  text: 'Trinidad und Tobago',
                },
                { value: 'von den Polargebieten', text: 'Polargebiete' },
              ]}
              onValueChange={handleNationalityChange}
              validation
              search
              className={`${classes.formControl} mb-4 p-0`}
            />
            {(paymentMethod === PAYMENT_METHODS.KREDU_BNPL ||
              paymentMethod === PAYMENT_METHODS.STRIPE_KLARNA) && (
              <MDBSelect
                id='occupation'
                required
                preventFirstSelection
                label='Beschäftigungsstatus'
                data={[
                  {
                    value: 'Angestellt (öffentlich)',
                    text: 'Angestellt (öffentlich)',
                  },
                  {
                    value: 'Angestellt (privatwirtschaftlich)',
                    text: 'Angestellt (privatwirtschaftlich)',
                  },
                  {
                    value: 'Arbeiter (öffentlich)',
                    text: 'Arbeiter (öffentlich)',
                  },
                  {
                    value: 'Arbeiter (privatwirtschaftlich)',
                    text: 'Arbeiter (privatwirtschaftlich)',
                  },
                  { value: 'Arbeitslos', text: 'Arbeitslos' },
                  {
                    value: 'Auszubildender/Praktikant',
                    text: 'Auszubildender/Praktikant',
                  },
                  { value: 'Beamter', text: 'Beamter' },
                  { value: 'Freiberufler', text: 'Freiberufler' },
                  {
                    value: 'Geringfügig beschäftigt',
                    text: 'Geringfügig beschäftigt',
                  },
                  { value: 'Gewerbetreibend', text: 'Gewerbetreibend' },
                  { value: 'Handwerker in Rente', text: 'Handwerker in Rente' },
                  { value: 'Hartz IV', text: 'Hartz IV' },
                  {
                    value: 'Rentner/Pensionär/Pensionist',
                    text: 'Rentner/Pensionär/Pensionist',
                  },
                  { value: 'Schüler', text: 'Schüler' },
                  {
                    value: 'Selbstständiger Handwerker',
                    text: 'Selbstständiger Handwerker',
                  },
                  {
                    value: 'Selbstständiger in Rente',
                    text: 'Selbstständiger in Rente',
                  },
                  { value: 'Student', text: 'Student' },
                  { value: 'Wehrdienstleistend', text: 'Wehrdienstleistend' },
                  { value: 'Zivildienstleistend', text: 'Zivildienstleistend' },
                ]}
                onValueChange={handleOccupationChange}
                validation
                search
                className={`${classes.formControl} mb-4 p-0`}
              />
            )}
            {(paymentMethod === PAYMENT_METHODS.KREDU_BNPL ||
              paymentMethod === PAYMENT_METHODS.STRIPE_KLARNA) && (
              <MDBInput
                type='number'
                label='Nettogehalt (€)'
                required
                id='netIncome'
                onChange={handleNetIncomeChange}
                className={`${classes.formControl} mb-4 py-1 ps-2`}
              />
            )}

            {/* <MDBBtn
            variant='light'
            className='fw-bold font-blue btn-back mt-4 mt-md-5 me-5 d-none d-md-inline-block'
          >
            <img src={back} alt='back' className='me-3'></img>Zurück
          </MDBBtn> */}

            {isError && <FormError errors={[errorMessage]} />}
            <MDBBtn
              variant='light'
              type='submit'
              className='btn-yellow mt-2 waves-effect'
            >
              {isLoading && (
                <MDBSpinner
                  size='sm'
                  role='status'
                  tag='span'
                  className='me-2'
                />
              )}
              Weiter
            </MDBBtn>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default RegistrationAdditionalInformation
