import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { MDBBtn } from 'mdb-react-ui-kit'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../data/constants'
import inProcess from '../../assets/img/in-process-icon.svg'

function StripePaymentProcessing() {
  const navigate = useNavigate()  

  const handleButtonClick = () => {
    navigate(ROUTES.CART)
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Zahlung wird bearbeitet.</title>
        <meta name='description' content='KREDU - Zahlung abgebrochen' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='text-center vh-100 pt-lg-5 container'>
        <div className='container-heigh row'>
          <div className='text-center mx-auto col-12 col-sm-10 col-md-6'>
            <div className='text-center box-width'>
              <img
                className='mb-2 mb-md-3 img-fluid'
                src={inProcess}
                alt='success'
              ></img>
              <h2 className='mb-3 fw-bold'> Die Zahlung wird bearbeitet.</h2>
              <p className='pt-4 pt-md-0 text-center ps-0 mb-4 fw-normal registration-box-info'>
                Deine Zahlung wird derzeit von Klarna bearbeitet. Wir werden
                dich informieren, sobald sie abgeschlossen ist. Deine Bestellung
                wird automatisch erstellt, sobald die Zahlung erfolgreich
                durchgeführt wurde.
              </p>

              <MDBBtn
                type='button'
                onClick={handleButtonClick}
                className='btn-yellow mb-5 mb-md-0 mt-auto mt-md-3 waves-effect'
              >
                Zurück zum Warenkorb
              </MDBBtn>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default StripePaymentProcessing
