import { useSelector } from 'react-redux'
import { MDBCard } from 'mdb-react-ui-kit'
import { Fragment } from 'react'
import { format } from 'date-fns'
import invoiceService from '../services/invoiceService'
import fileDownload from 'js-file-download'
import { toast } from 'react-toastify'
import { ERROR_MESSAGES } from '../../../../data/constants'

function Invoices({ invoices, orderStatus }) {
  const { user } = useSelector((state) => state.auth)

  const downloadInvoice = async (invoiceId, invoiceNumber, e) => {
    e.preventDefault()

    try {
      const invoiceBinary = await invoiceService.downloadInvoice(
        user.token,
        invoiceId
      )
      fileDownload(invoiceBinary, `KREDU_Rechnung_${invoiceNumber}.pdf`)
    } catch (error) {
      toast.error(ERROR_MESSAGES.DEFAULT_ERROR_MESSAGE)
    }
  }

  const mapInvoiceStatus = (status) => {
    switch (status) {
      case 'OPEN':
        return 'Zu bezahlen'
      case 'PAID':
        return 'Bezahlt'
      case 'CANCELED':
        return 'Gekündigt'
      default:
        return ''
    }
  }

  return (
    <Fragment>
      <MDBCard className='mb-5 px-3 pt-4 pb-3 mx-xl-0 mx-sm-5 mx-1'>
        {invoices &&
          Array.isArray(invoices) &&
          invoices.length > 0 &&
          invoices.map((invoice) => (
            <div key={invoice.invoiceId}>
              <div className='d-flex justify-content-between mb-1 col'>
                <span className=''>Rechnung {invoice.invoiceNumber}</span>
                <span>
                  {invoice.totalAmount.toFixed(2).replace('.', ',')} €
                </span>
              </div>
              <div className='d-flex justify-content-between mb-1 col'>
                <span className='text-muted s-text'>
                  Erstellt am{' '}
                  {format(new Date(invoice.createdAt), 'dd.MM.yyyy')}
                </span>
                {orderStatus === 'cancelled' ? (
                  <span className='text-muted s-text'>Widerrufen</span>
                ) : (
                  <span className='text-muted s-text'>
                    {mapInvoiceStatus(invoice.paymentStatus)}
                  </span>
                )}
              </div>
              <div
                className={`d-flex justify-content-between ${
                  invoices[invoices.length - 1].invoiceId !== invoice.invoiceId
                    ? 'payment-box'
                    : ''
                } mb-3 pb-2 col`}
              >
                <span className='text-muted s-text'>
                  Fällig am {format(new Date(invoice.dueDate), 'dd.MM.yyyy')}
                </span>
                <a
                  className='s-text font-blue'
                  href={`rechnung-herunterladen/${invoice.invoiceNumber}`}
                  onClick={(e) =>
                    downloadInvoice(invoice.invoiceId, invoice.invoiceNumber, e)
                  }
                >
                  Herunterladen
                </a>
              </div>
            </div>
          ))}
      </MDBCard>
    </Fragment>
  )
}

export default Invoices
