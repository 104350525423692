import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useLocation, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import kreduLogo from '../assets/img/Kredu-logo.svg'
import arrowForward from '../assets/img/arrow-forward.svg'
import checkBlue from '../assets/img/check-blue.svg'

import cartIcon from '../assets/img/cart-icon.svg'

import classes from './RegistrationProgressDesktop.module.css'
import { ROUTES } from '../data/constants'
import { PAYMENT_METHODS } from '../data/constants'
import { REGISTRATION_STEPS } from '../data/constants'

import { revertCartSubmit } from '../features/cart/redux/cartSlice'

function RegistrationProgressDesktop() {
  const [cartItemsTotalQty, setCartItemsTotalQuantity] = useState(0)
  const { cartItems, paymentMethod } = useSelector((state) => state.cart)
  const { finishedSteps } = useSelector((state) => state.registration)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const totalCartItemsQuantity = cartItems.reduce(
      (partialSum, x) => partialSum + x.quantity,
      0
    )
    setCartItemsTotalQuantity(totalCartItemsQuantity)
  }, [cartItems])

  const isRegistrationStepAlreadyDone = (stepName) => {
    if (finishedSteps.includes(stepName)) {
      return true
    }
  }

  const hasKredupayVoucherAbove100 = () => {
    return cartItems.some(
      (item) =>
        item.name !== null &&
        item.name.toLowerCase().includes('kredupay') &&
        item.price >= 100
    )
  }

  const handleGoToCartClick = (e) => {
    e.preventDefault()
    dispatch(revertCartSubmit())
    navigate(ROUTES.CART)
  }

  return (
    <div className='d-md-block d-none min-vh-100'>
      <div className='container d-inline-block side-progress-bar text-start ps-4'>
        <Link to='/'>
          <img src={kreduLogo} alt='logo' className='kredu-logo ms-5'></img>
        </Link>

        <div className='row'>
          <div className='text-center mb-5 ms-5 col-2'>
            <Link
              to='/warenkorb'
              onClick={handleGoToCartClick}
              className={`${classes.badgeLink}  align-items-center d-flex `}
            >
              <span
                className={`${classes.badge} ${
                  cartItemsTotalQty > 0 ? '' : 'd-none'
                }`}
              >
                {cartItemsTotalQty}
              </span>
              <img
                src={cartIcon}
                className={classes.cartImg}
                alt='shopping-cart'
              ></img>
              <span className='ms-2 font-black'>Ändern</span>
            </Link>
          </div>
        </div>
        <div className='mb-4 row'>
          <div className='col-2'>
            {(location.pathname.endsWith(ROUTES.REGISTRATION_EMAIL) ||
              location.pathname.endsWith(
                ROUTES.REGISTRATION_EMAIL_ACTIVATION_LINK_SENT
              ) ||
              location.pathname.endsWith(
                ROUTES.REGISTRATION_EMAIL_CONFIRMATION
              )) && <img src={arrowForward} alt='arrow' className='me-2'></img>}
            {isRegistrationStepAlreadyDone(REGISTRATION_STEPS.EMAIL) && (
              <img src={checkBlue} alt='check' className='me-4'></img>
            )}
          </div>
          <div className='col-10'>
            <p
              className={`mb-0 ${
                location.pathname.endsWith(ROUTES.REGISTRATION_EMAIL) ||
                location.pathname.endsWith(
                  ROUTES.REGISTRATION_EMAIL_ACTIVATION_LINK_SENT
                ) ||
                location.pathname.endsWith(
                  ROUTES.REGISTRATION_EMAIL_CONFIRMATION
                )
                  ? ''
                  : 'text-muted'
              }`}
            >
              E-Mail Adresse
            </p>
          </div>
        </div>
        <div className='mb-4 row'>
          <div className='col-2'>
            {(location.pathname.endsWith(
              ROUTES.REGISTRATION_MOBILE_PHONE_NUMBER
            ) ||
              location.pathname.endsWith(
                ROUTES.REGISTRATION_OTP_CONFIRMATION
              )) && <img src={arrowForward} alt='arrow' className='me-2'></img>}
            {isRegistrationStepAlreadyDone(
              REGISTRATION_STEPS.MOBILE_PHONE_NUMBER
            ) && <img src={checkBlue} alt='check' className='me-4'></img>}
          </div>
          <div className='col-10'>
            <p
              className={`mb-0 ${
                location.pathname.endsWith(
                  ROUTES.REGISTRATION_MOBILE_PHONE_NUMBER
                ) ||
                location.pathname.endsWith(ROUTES.REGISTRATION_OTP_CONFIRMATION)
                  ? ''
                  : 'text-muted'
              }`}
            >
              Handynummer
            </p>
          </div>
        </div>
        <div className='mb-4 row'>
          <div className='col-2'>
            {location.pathname.endsWith(ROUTES.REGISTRATION_PERSONAL_DATA) && (
              <img src={arrowForward} alt='arrow' className='me-2'></img>
            )}
            {isRegistrationStepAlreadyDone(
              REGISTRATION_STEPS.PERSONAL_DATA
            ) && <img src={checkBlue} alt='check' className='me-4'></img>}
          </div>
          <div className='col-10'>
            <p
              className={`mb-0 ${
                location.pathname.endsWith(ROUTES.REGISTRATION_PERSONAL_DATA)
                  ? ''
                  : 'text-muted'
              }`}
            >
              Persönliche Daten
            </p>
          </div>
        </div>
        {(paymentMethod === PAYMENT_METHODS.KREDU_BNPL ||
          hasKredupayVoucherAbove100()) && (
          <div className='mb-4 row'>
            <div className='col-2'>
              {(location.pathname.endsWith(
                ROUTES.REGISTRATION_ADDITIONAL_PERSONAL_DATA
              ) ||
                location.pathname.endsWith(
                  ROUTES.REGISTRATION_ADDITIONAL_DOCUMENTS
                )) && (
                <img src={arrowForward} alt='arrow' className='me-2'></img>
              )}
              {isRegistrationStepAlreadyDone(
                REGISTRATION_STEPS.ADDITIONAL_PERSONAL_DATA
              ) && <img src={checkBlue} alt='check' className='me-4'></img>}
            </div>
            <div className='col-10'>
              <p
                className={`mb-0 ${
                  location.pathname.endsWith(
                    ROUTES.REGISTRATION_ADDITIONAL_PERSONAL_DATA
                  )
                    ? ''
                    : 'text-muted'
                }`}
              >
                Zusätzliche Informationen
              </p>
            </div>
          </div>
        )}
        <div className='mb-4 row'>
          <div className='col-2'>
            {(location.pathname.endsWith(
              ROUTES.REGISTRATION_BANK_VERIFICATION
            ) ||
              location.pathname.endsWith(
                ROUTES.REGISTRATION_BANK_VERIFICATION_PENDING
              )) && <img src={arrowForward} alt='arrow' className='me-2'></img>}
            {isRegistrationStepAlreadyDone(
              REGISTRATION_STEPS.BANK_VERIFICATION
            ) && <img src={checkBlue} alt='check' className='me-4'></img>}
          </div>
          <div className='col-10'>
            <p
              className={`mb-0 ${
                location.pathname.endsWith(
                  ROUTES.REGISTRATION_BANK_VERIFICATION
                )
                  ? ''
                  : 'text-muted'
              }`}
            >
              1-Cent-Verifizierung
            </p>
          </div>
        </div>
        {/* {(paymentMethod === PAYMENT_METHODS.KREDU_BNPL ||
          hasKredupayVoucherAbove100()) && (
          <div className='mb-4 row'>
            <div className='col-2'>
              {location.pathname.endsWith(
                ROUTES.REGISTRATION_IDENTIFICATION
              ) && <img src={arrowForward} alt='arrow' className='me-2'></img>}
              {isRegistrationStepAlreadyDone(
                REGISTRATION_STEPS.IDENTIFICATION
              ) && <img src={checkBlue} alt='check' className='me-4'></img>}
            </div>
            <div className='col-10'>
              <p
                className={`mb-0 ${
                  location.pathname.endsWith(ROUTES.REGISTRATION_IDENTIFICATION)
                    ? ''
                    : 'text-muted'
                }`}
              >
                ID-Prüfung
              </p>
            </div>
          </div>
        )} */}
        <div className='mb-4 row'>
          <div className='col-2'>
            <img src={arrowForward} alt='arrow' className='me-2 d-none'></img>
            <img src={checkBlue} alt='check' className='me-4 d-none'></img>
          </div>
          <div className='col-10'>
            <p className='mb-0 text-muted'>Kasse</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationProgressDesktop
