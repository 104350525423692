import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, Fragment } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import classes from './RegistrationEmailActivationLinkSent.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'

import sendEmail from '../../assets/img/send-email.svg'

function RegistrationEmailActivationLinkSent() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const email = searchParams.get('email')

  useEffect(() => {
    const isEmailActivationLinkSent = localStorage.getItem(
      'is-email-activation-link-sent'
    )
    if (!isEmailActivationLinkSent) navigate('/')
  }, [navigate])

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Aktivierungslink gesendet</title>
        <meta name='description' content='KREDU - Aktivierungslink gesendet' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='vh-100 container'>
        <div
          className={`${classes.registrationFormBox} row d-md-inline-block ms-lg-3`}
        >
          <div className='text-center'>
            <div className='text-center'>
              <img src={sendEmail} alt='email' className='my-5'></img>
              <p>
                Wir haben eine E-Mail mit einem Aktivierungslink an deine
                E-Mail-Adresse
                <br className='d-none d-sm-block' /> <b>{email} </b>
                gesendet. Bitte <br className='d-none d-sm-block' />
                klicke auf den Aktivierungslink, um fortzufahren.
              </p>
              <p>
                Falls du die E-Mail nicht finden kannst,
                <br className='d-none d-sm-block' /> sieh bitte auch in deinem
                Spam-Ordner nach.
              </p>
              <Link
                className={classes.registrationLink}
                to='/registrierung/email'
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  fontSize='20px'
                  className='me-2'
                />{' '}
                Zurück zur Registrierung
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default RegistrationEmailActivationLinkSent
