import { Fragment } from 'react'
import { Helmet } from 'react-helmet-async'

import classes from './Imprint.module.css'

function Imprint() {
  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Impressum</title>
        <meta name='description' content='KREDU - Impressum' />
      </Helmet>
      <div id='impressum' class='py-5 container'>
        <div class='row py-4 py-md-5'>
          <div class='col-lg-10 offset-lg-1 col-12 pb-5'>
            <h1 class='text-center mt-1 mb-5 fw-bold'>Impressum</h1>
            <h3 className='fw-bold'>Anbieterkennzeichnung nach § 5 TMG</h3>
            <p>Kredu GmbH</p>
            <h3 class='mt-5 fw-bold'>Geschäftsanschrift</h3>
            <p>
              Bundesallee 220
              <br />
              D-10719 Berlin
              <br />
              Deutschland
              <br />
              +49 (0)30 399 15 0 15
              <br />
              <a href='mailto:support@kredu.de' className={classes.link}>
                support@kredu.de
              </a>
            </p>
            <h3 class='mt-5 fw-bold'>Geschäftsführer</h3>
            <p>Ali Özen</p>
            <h3 class='mt-5 fw-bold'>Sitz der Gesellschaft </h3>
            <p>Berlin</p>
            <h3 class='mt-5 fw-bold'>Registereintrag</h3>
            <p>
              Amtsgericht Charlottenburg
              <br />
              HRB 179852 B<br />
              Ust.-IdNr.: DE 308 80 2003
              <br />
              <br />
              Erlaubnis nach § 34 c Abs. 1 Nr. 2 GewO. Aufsichtsbehörde:
              Bezirksamt Friedrichshain Kreuzberg Berlin, Abteilung Wirtschaft,
              Ordnung und Ordnungsamt, Yorckstraße 4-11, 10965 Berlin
            </p>
            <h3 class='mt-5 fw-bold'>Konzeption, Entwicklung und Umsetzung</h3>
            <p>Kredu GmbH, Copyright © 2022</p>
            <h3 class='mt-5 fw-bold'>Bildnachweis</h3>
            <p class='pb-4'>
              Fotolia,
              <br />
              Shutterstock,
              <br />
              FontAwesome,
              <br />
              IonicFramework,
              <br />
              Eigene Arbeit
            </p>

            <hr />

            <p class='my-5'>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
              Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
              können wir jedoch keine vollständige Gewähr übernehmen.
              <br />
              <br />
              Als Diensteanbieter sind wir gemäß § 6 Abs. 1 MDStV und § 8 Abs. 1
              TDG für eigene Inhalte auf diesen Seiten nach den allgemeinen
              Gesetzen verantwortlich. Diensteanbieter sind jedoch nicht
              verpflichtet, die von ihnen übermittelten oder gespeicherten
              fremden Informationen zu überwachen oder nach Umständen zu
              forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
              Informationen nach den allgemeinen Gesetzen bleiben hiervon
              unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
              Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
              Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              die betreffenden Inhalte umgehend entfernen.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Imprint
