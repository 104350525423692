import { useState, Fragment } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import userService from '../../services/userService'
import { MDBInput, MDBSelect, MDBBtn, MDBSpinner } from 'mdb-react-ui-kit'
import FormError from '../../components/FormError'
import { ROUTES } from '../../data/constants'

import classes from './RegistrationPersonalInformation.module.css'


function RegistrationPersonalInformation() {
  const [salutation, setSalutation] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [birthdate, setBirthdate] = useState('')
  const [streetName, setStreetName] = useState('')
  const [houseNumber, setHouseNumber] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { user } = useSelector((state) => state.auth)
  const { paymentMethod } = useSelector((state) => state.cart)

  const navigate = useNavigate()

  const handleSalutationChange = (val) => {
    setSalutation(val.value.toString())
  }

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value.toString())
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value.toString())
  }

  const handleBirthdateChange = (e) => {
    setBirthdate(e.target.value.toString())
  }

  const handleStreetNameChange = (e) => {
    setStreetName(e.target.value.toString())
  }

  const handleHouseNumberChange = (e) => {
    setHouseNumber(e.target.value.toString())
  }

  const handlePostalCodeChange = (e) => {
    const value = e.target.value
    const isValid = /^\d{5}$/.test(value)
    if (isValid || value === '') {
      setPostalCode(value.toString())
    } else {
      e.preventDefault()
    }
  }

  const handleCityChange = (e) => {
    setCity(e.target.value.toString())
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (isLoading) return
    setIsError(false)
    setErrorMessage('')
    setIsLoading(true)

    const personalData = {
      salutation: salutation,
      firstName: firstName,
      lastName: lastName,
      birthdate: birthdate,
      streetName: streetName,
      houseNumber: houseNumber,
      postalCode: postalCode,
      city: city,
    }

    userService
      .registerPersonalData(user.token, paymentMethod, personalData)
      .then((response) => {
        if (response !== null && response !== '') {
          setIsError(true)
          if (response === 'TOO_YOUNG') {
            setErrorMessage(
              'Du musst mindestens 18 Jahre alt sein, um dich bei KREDU zu registrieren.'
            )
          } else if (response === 'DUPLICATE_ACCOUNT') {
            setErrorMessage(
              'Du hast bereits ein Konto bei KREDU. Bitte logge dich ein.'
            )
          }
          setIsLoading(false)
        } else {
          if (paymentMethod === 'KREDU_BNPL') {
            navigate(
              ROUTES.REGISTRATION +
                '/' +
                ROUTES.REGISTRATION_ADDITIONAL_PERSONAL_DATA
            )
          } else {
            navigate(
              ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_BANK_VERIFICATION
            )
          }
        }
      })
      .catch((error) => {
        setIsError(true)
        if (error.response.status === 400) {
          if (error.response.data === 'INVALID_BIRTHDATE') {
            setErrorMessage(
              'Das eingegebene Geburtsdatum hat ein falsches Format.'
            )
          }
        }
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Persönliche Daten</title>
        <meta name='description' content='KREDU - Persönliche Daten' />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='h-100 container'>
        <div
          className={`d-md-inline-block ${classes.registrationFormBox} mx-auto me-lg-0 ms-lg-3`}
        >
          <h2 className='mb-3 d-none d-md-block'>Persönliche Daten</h2>
          <p className='pt-4 pt-md-0 text-start ps-3 ps-md-0 mb-5 fw-normal'>
            Wir benötigen ein paar Informationen, um loszulegen.
          </p>
          <form
            onSubmit={handleFormSubmit}
            className={`${classes.formBox} form-box px-3 px-md-0`}
          >
            <div className='row'>
              <div className='col'>
                <MDBSelect
                  id='salutation'
                  required
                  preventFirstSelection
                  label='Anrede'
                  data={[
                    { text: 'Frau', value: 'Frau' },
                    { text: 'Herr', value: 'Herr' },
                  ]}
                  onChange={(val) => handleSalutationChange(val)}
                  validation
                  className={`${classes.formControl} mb-4 p-0`}
                />
              </div>
            </div>

            <MDBInput
              id='firstname'
              type='text'
              label='Vorname'
              onChange={handleFirstNameChange}
              required
              className={`${classes.formControl} mb-4`}
            />
            <MDBInput
              id='lastname'
              type='text'
              label='Nachname'
              onChange={handleLastNameChange}
              required
              className={`${classes.formControl} mb-4`}
            />
            <MDBInput
              id='birthdate'
              type='text'
              pattern='^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$'
              label='Geburtsdatum (TT.MM.JJJJ)'
              required
              onChange={handleBirthdateChange}
              className={`${classes.formControl} mb-4`}
            />

            <div className='row'>
              <div className='col-sm-8 col-12 pe-sm-2 pe-md-0'>
                <MDBInput
                  id='street'
                  type='text'
                  label='Straße'
                  required
                  onChange={handleStreetNameChange}
                  className={`${classes.formControl} mb-4`}
                />
              </div>
              <div className='col-sm-4 col-12'>
                <MDBInput
                  id='streetNumber'
                  type='text'
                  label='Nummer'
                  required
                  onChange={handleHouseNumberChange}
                  className={`${classes.formControl} mb-4`}
                />
              </div>
            </div>
            <div className='row mb-2'>
              <div className='col-sm-5 col-12 pe-sm-2 pe-md-0'>
                <MDBInput
                  id='zipCode'
                  type='text'
                  label='Plz'
                  pattern='\d{5}'
                  required
                  onChange={handlePostalCodeChange}
                  className={`${classes.formControl} mb-4`}
                />
              </div>
              <div className='col-sm-7 col-12'>
                <MDBInput
                  id='city'
                  type='text'
                  label='Ort'
                  required
                  onChange={handleCityChange}
                  className={`${classes.formControl} mb-4`}
                />
              </div>
            </div>

            {/* <button
            className={`${classes.btnBack} font-blue btn-back mb-2 fw-bold  me-5 d-none d-md-inline-block`}
          >
            <img src={back} alt='back' className='me-3 d-inline-block' />
            <span>Zurück</span>
          </button> */}

            {isError && <FormError errors={[errorMessage]} />}
            <MDBBtn
              type='submit'
              className={`${classes.btnYellow} btn-yellow btn mt-4 waves-effect`}
            >
              {isLoading && (
                <MDBSpinner
                  size='sm'
                  role='status'
                  tag='span'
                  className='me-2'
                />
              )}
              Weiter
            </MDBBtn>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default RegistrationPersonalInformation
