import { useState, Fragment } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import userService from '../../services/userService'
import { MDBInputGroup, MDBBtn, MDBSpinner } from 'mdb-react-ui-kit'
import FormError from '../../components/FormError'
import { ROUTES } from '../../data/constants'

import classes from './RegistrationMobilePhoneNumber.module.css'

function RegistrationMobilePhoneNumber() {
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { user } = useSelector((state) => state.auth)

  const navigate = useNavigate()

  const handleMobilePhoneNumberChange = (e) => {
    setMobilePhoneNumber(e.target.value.toString())
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setIsError(false)
    setErrorMessage('')
    setIsLoading(true)

    userService
      .requestMobilePhoneNumberUpdate(user.token, mobilePhoneNumber)
      .then((response) => {
        if(response === "NOT_GERMAN_NUMBER") {
          setIsError(true)
          setErrorMessage('Das ist keine gültige deutsche Handynummer.')
          setIsLoading(false)
          return
        } else if(response === "NUMBER_ALREADY_EXISTS") {
          setIsError(true)
          setErrorMessage('Diese Mobilnummer ist bereits registriert.')
          setIsLoading(false)
          return
        } 

        localStorage.setItem('is-otp-sent', true)
        navigate(
          ROUTES.REGISTRATION + '/' + ROUTES.REGISTRATION_OTP_CONFIRMATION
        )
      })
      .catch((error) => {
        setIsError(true)
        if (error.response.status === 429) {
          setErrorMessage(
            'Du kannst nur fünf SMS in einer Minute schicken. Bitte warte eine Minute und versuche es erneut.'
          )
        }
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - Handynummer</title>
        <meta
          name='description'
          content='KREDU - Handynummer'
        />
        <meta name='robots' content='noindex,nofollow' />
      </Helmet>
      <div className='vh-100 pt-lg-3 container'>
        <div
          className={`${classes.registrationFormBox} d-md-inline-block mx-auto me-lg-0 ms-lg-3 pe-md-3`}
        >
          <h2 className='mb-3 d-none d-md-block'>Handynummer</h2>
          <p className='pt-4 pt-md-0 text-start ps-3 ps-md-0 mb-5 fw-normal'>
            Bitte gib eine gültige Handynummer ein, an die wir einen{' '}
            Bestätigungscode senden können.
          </p>
          <form
            onSubmit={handleFormSubmit}
            className={`${classes.formBox} form-box px-3 px-md-0 w-100`}
          >
            <MDBInputGroup noBorder textBefore='+49' className='d-flex mb-4'>
              <input
                className={`${classes.formControl} form-control pe-0  rounded mb-0`}
                id='registrationPhoneNumber'
                type='number'
                placeholder='Handynummer'
                required
                pattern='^(?:.*[0-9]){11,}$'
                onChange={handleMobilePhoneNumberChange}
              />
            </MDBInputGroup>

            {/* <MDBBtn className='font-blue fw-bold btn-back mt-4 mt-md-5 me-5 d-none d-md-inline-block'>
                <img
                  src={back}
                  alt='back'
                  className='me-3'
                ></img>
                Zurück
              </MDBBtn> */}
            {isError && <FormError errors={[errorMessage]} />}
            <MDBBtn
              type='submit'
              className={`${classes.btnYellow} btn-yellow btn mt-2 waves-effect`}
            >
              {isLoading && (
                <MDBSpinner
                  size='sm'
                  role='status'
                  tag='span'
                  className='me-2'
                />
              )}
              Weiter
            </MDBBtn>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default RegistrationMobilePhoneNumber
