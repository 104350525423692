import { Link } from 'react-router-dom'

import classes from '../assets/css/CartEmpty.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartCircleXmark } from '@fortawesome/pro-light-svg-icons'

function EmptyCart() {
  return (
    <div className='container vh-100 pt-lg-5'>
      <div className='row container-heigh'>
        <div
          size='12'
          sm='10'
          md='6'
          className='col-12 col-sm-10 col-md-6 text-center offset-sm-1 offset-md-3'
        ></div>
        
        <div className='text-center box-width'>
          <FontAwesomeIcon icon={faCartCircleXmark} fontSize='45px' className='mb-4 font-blue' />
          <h3 className='fw-bold mb-3'>Dein Warenkorb ist leer</h3>
          <p>Bitte füge Artikel zu deinem Warenkorb hinzu, um fortzufahren.</p>
          <Link to='/alle-produkte' className={`${classes.fontBlue} mt-4 text-uppercase`}>unsere Produkte entdecken</Link>
        </div>
      </div>
    </div>
  )
}

export default EmptyCart
