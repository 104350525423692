import { useState } from 'react'

import { Link } from 'react-router-dom'

import classes from './DashboardNavigationMobile.module.css'

import purchaseIcon from '../assets/img/purchase-history-icon.svg'
import purchaseIconBlue from '../assets/img/purchase-history-icon-blue.svg'
import accountIcon from '../assets/img/my-account-icon.svg'
import accountIconBlue from '../assets/img/my-account-icon-blue.svg'

import { ROUTES } from '../data/constants'

function DashboardNavigationMobile() {  
  const [showNav, setShowNav] = useState(false)

  const handleNavbarItemClick = (e) => {
    setShowNav(false)
  }

  return (
      <div className='d-block d-md-none dashboardNavMobile' id='dashboardNavMobile'>
        <footer className='text-center fixed-bottom bg-white'>
          <div className='text-center pt-2 pb-1'>
            <div className='container px-0 d-flex text-center justify-content-around'>
              <Link to={ROUTES.DASHBOARD + '/' + ROUTES.DASHBOARD_ORDERS} onClick={handleNavbarItemClick}>
                <img
                  src={purchaseIcon} alt='bestellungen'
                  className='mx-auto d-none'
                ></img>
                <img
                  src={purchaseIconBlue} alt='bestellungen'
                  className='mx-auto d-block'
                ></img>
                <span className={classes.dashNav}> Bestellungen </span>
              </Link>
              <Link to={ROUTES.DASHBOARD + '/' + ROUTES.DASHBOARD_USER_PROFILE} onClick={handleNavbarItemClick}>
                <img
                  src={accountIcon} alt='konto'
                  className='mx-auto d-none'
                ></img>
                <img
                  src={accountIconBlue} alt='konto'
                  className='mx-auto d-block'
                ></img>
                <span className={classes.dashNav}>Mein Profil</span>
              </Link>
            </div>
          </div>
        </footer>
      </div>
  )
}

export default DashboardNavigationMobile
