import { Fragment, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { resetRecentlyAddedCartProductId } from '../../features/cart/redux/cartSlice'
import { Link, useLocation } from 'react-router-dom'
import {
  MDBBtn,
  MDBAccordion,
  MDBAccordionItem,
  // MDBModal,
  // MDBModalDialog,
  // MDBModalContent,
  // MDBModalHeader,
  // MDBModalTitle,
  // MDBModalBody,
  // MDBModalFooter,
} from 'mdb-react-ui-kit'
import classes from './Homepage.module.css'
import HomepageNavigation from '../../components/HomepageNavigation'

import heroMobile from '../../assets/img/HeroMobile.svg'
import heroDesktop from '../../assets/img/HeroDesktop.svg'
import heroDesktopXL from '../../assets/img/heroDesktopXL.svg'
import paysafe from '../../assets/img/v-paysafe.svg'
import paysafe50 from '../../assets/img/products/paysafecard-50-euro.svg'
import google50 from '../../assets/img/products/google-play-50-euro.svg'
import playstation50 from '../../assets/img/products/sony-playstation-50-euro.svg'
import xbox25 from '../../assets/img/products/xbox-25-euro.svg'
import steam50 from '../../assets/img/products/steam-50-euro.svg'
import google from '../../assets/img/v-google.svg'
import playStation from '../../assets/img/v-playstation.svg'
import xbox from '../../assets/img/v-xbox.svg'
import steam from '../../assets/img/v-steam.svg'
import zalando from '../../assets/img/v-zalando.svg'
import amazon from '../../assets/img/v-amazon.svg'
import netflix from '../../assets/img/v-netflix.svg'
import tmobile from '../../assets/img/v-tmobile.svg'
import vodafone from '../../assets/img/v-vodafone.svg'
import congstar from '../../assets/img/v-congstar.svg'
import blau from '../../assets/img/v-blau.svg'
import bigpoint from '../../assets/img/v-bigpoint.svg'
import bildmobil from '../../assets/img/v-bildmobil.svg'
import alditalk from '../../assets/img/v-alditalk.svg'
import fonic from '../../assets/img/v-fonic.svg'
import lebara from '../../assets/img/v-lebara.svg'
import eplus from '../../assets/img/v-eplus.svg'
import deezer from '../../assets/img/v-deezer.svg'
import klarmobil from '../../assets/img/v-klarmobil.svg'
import spotify from '../../assets/img/v-spotify.svg'
import turkcell from '../../assets/img/v-turkcell.svg'
import yourfone from '../../assets/img/v-yourfone.svg'
import nintendo from '../../assets/img/v-nintendo.svg'
import otelo from '../../assets/img/v-otelo.svg'
import ortel from '../../assets/img/v-ortel.svg'
import o2 from '../../assets/img/v-02.svg'
import lycamobile from '../../assets/img/v-lycamobile.svg'
import mobi from '../../assets/img/v-mobi.svg'

import moneyBags from '../../assets/img/money-bags.svg'
import timeClock from '../../assets/img/time-clock.svg'
import calendar from '../../assets/img/calendar.svg'
import smartphone from '../../assets/img/smartphone-logo.svg'
import loveit from '../../assets/img/love-it-hand-logo.svg'
import creditDisabled from '../../assets/img/credit-card-disable-logo.svg'
import headphones from '../../assets/img/headphones-customer-support-human.svg'
import timer from '../../assets/img/timer.svg'
import lock from '../../assets/img/lock.svg'

import how1 from '../../assets/img/how1.2.svg'
import how2 from '../../assets/img/how1.png'
import how3 from '../../assets/img/how3.2.svg'
import how4 from '../../assets/img/how3.png'
import how1Mobile from '../../assets/img/how1.2.svg'
import how2Mobile from '../../assets/img/how1.png'
import how3Mobile from '../../assets/img/how3.2.svg'

import schufa from '../../assets/img/schufa.svg'
import microsoft from '../../assets/img/microsoft.svg'
import sofort from '../../assets/img/SOFORT.svg'
import webId from '../../assets/img/WebID-logo.svg'

function HomepageMain(props) {
  //const [basicModal, setBasicModal] = useState(true)
  //const toggleOpen = () => setBasicModal(!basicModal)

  const dispatch = useDispatch()

  const howItWorksSection = useRef()

  const location = useLocation()

  useEffect(() => {
    dispatch(resetRecentlyAddedCartProductId())
  }, [dispatch])

  useEffect(() => {
    if (location.hash === '#soFunktioniert') {
      setTimeout(() => {
        window.scrollTo({
          top: howItWorksSection.current.offsetTop,
          behavior: 'smooth',
        })
      }, 100)
    }
  })

  return (
    <Fragment>
      <Helmet>
        <title>KREDU - digitale Gutscheine auf Rechnung</title>
        <meta
          name='description'
          content='Erhalte schnell und einfach digitale Gutscheine auf
          Rechnung'
        />
      </Helmet>
      {/* <MDBModal show={basicModal} setShow={setBasicModal} tabIndex='-1'>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>              
              <MDBBtn
                className='btn-close'
                color='none'
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Liebe Kunden, <br />
              aufgrund einer Störung im Rechenzentrum kommt es vorübergehend zu
              Beeinträchtigungen.
              <br />
              Es wird bereits mit Hochdruck an der Wiederherstellung des
              Dienstes gearbeitet.
              <br />
              Wir entschuldigen uns für etwaige Unannehmlichkeiten und bedanken
              uns für Ihr Verständnis.
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen}>
              Schließen
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal> */}
      <section className={classes.heroMain}>
        <HomepageNavigation />
        <div className='container-fluid px-0'>
          <div className={`row ${classes.heroBg}`}>
            <div
              className={`${classes.heroMainText} col-lg-5  order-2 order-lg-1`}
            >
              <h1 className='mb-4 d-md-none'>
                Erhalte schnell und einfach{' '}
                <span className='fw-bold'>digitale Gutscheine</span> auf
                Rechnung
              </h1>
              <h1 className='d-none d-md-block my-0 pb-0'>
                Erhalte schnell und einfach
              </h1>
              <h1 className='d-none d-md-block fw-bold my-0 py-0 text-uppercase'>
                digitale Gutscheine
              </h1>
              <h1 className='d-none d-md-block my-0 pt-0'>auf Rechnung</h1>
              <Link
                to='/alle-produkte'
                className={`${classes.fitContent} px-3 px-sm-0 d-block d-lg-none`}
              >
                <MDBBtn className={`${classes.btnYellow} btn mx-auto `}>
                  Jetzt kaufen
                </MDBBtn>
              </Link>
              <Link
                to='/alle-produkte'
                className='ps-2 pe-3 px-md-0 d-none d-lg-block'
              >
                <MDBBtn className={`${classes.btnYellow} btn mx-auto  my-4`}>
                  Jetzt kaufen
                </MDBBtn>
              </Link>
            </div>
            <div
              className={`${classes.imgBox} col-lg-7 text-end order-1 order-lg-2`}
            >
              <picture>
                <source media='(min-width:1200px)' srcSet={heroDesktopXL} />
                <source media='(min-width:991px)' srcSet={heroDesktop} />
                <img
                  src={heroMobile}
                  alt='Vouchers'
                  className={`img-fluid ${classes.heroImg}`}
                />
              </picture>
            </div>
          </div>
          <div className={` row text-center`}>
            <div
              className={`${classes.infoHero} col text-center mb-3 mb-lg-5 pb-lg-5`}
            >
              {/* <p className={`mb-1 ${classes.pfeatures}`}>
                Bald wieder verbessert Verfügbar:
              </p>
              <div className='d-flex justify-content-center mb-3'>
                <img
                  src={kredu50}
                  className={`${classes.heroVouchers} img-fluid`}
                  alt='paysafe'
                />
                <img
                  src={kredu100}
                  className={classes.heroVouchers}
                  alt='google'
                />
                <img
                  src={kredu250}
                  className={classes.heroVouchers}
                  alt='playStation'
                />
              </div> */}
            </div>
          </div>
          <div className={`${classes.features} row text-center`}>
            <div className='col-md-4 d-flex justify-content-center align-items-center mb-3 mb-md-0'>
              <img
                src={moneyBags}
                alt='money'
                width='28px'
                className='me-3 me-md-3'
              />
              <p className='mt-1 p-0'>bis zu 500 Euro*</p>
            </div>
            <div className='col-md-4 d-flex justify-content-center align-items-center mb-3 mb-md-0'>
              <img
                src={timeClock}
                alt='clock'
                width='28px'
                className='me-3 me-md-3'
              />
              <p className='mt-1 p-0'>24 Stunden am Tag</p>
            </div>
            <div className='col-md-4 d-flex justify-content-center'>
              <img
                src={calendar}
                alt='calendar'
                width='28px'
                className='me-3 me-md-3'
              />
              <p className='mt-1 p-0'>365 Tage im Jahr</p>
            </div>
            <span className='text-muted small mt-2'>
              * Bonität und Verifizierung vorausgesetzt
            </span>
          </div>
        </div>
      </section>

      <section className={`${classes.warumKredu} text-center`}>
        <div className='container px-0 px-md-3'>
          <div className='row'>
            <div className='col pb-5 my-2'>
              <h1 className='mb-3 text-uppercase'>
                Deine <span className={classes.kreduBox}>Vorteile</span>
              </h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-4 mt-3 mt-md-5'>
              <img
                src={timer}
                alt='timer'
                width='48px'
                className='mb-3 font-blue'
              />
              <h3 className='fw-bold'>30 Tage Zahlungsziel</h3>
              <p>Bonität vorausgesetzt</p>
            </div>
            <div className='col-md-6 col-lg-4 mt-5'>
              <img
                src={smartphone}
                alt='phone'
                width='48px'
                className='mb-3 font-blue'
              />
              <h3 className='fw-bold'>Komplett Digital</h3>
              <p>Codes per Email</p>
            </div>
            <div className='col-md-6 col-lg-4 mt-5'>
              <img
                src={loveit}
                alt='loveit'
                width='48px'
                className='mb-3 font-blue'
              />
              <h3 className='fw-bold'>Auch bei mittlerer Bonität</h3>
              <p>
                Vorausgesetzt keine harte Schufa Merkmale oder überfällige
                offene Rechnungen
              </p>
            </div>
            <div className='col-md-6 col-lg-4 mt-5'>
              <img
                src={creditDisabled}
                alt='credit'
                width='48px'
                className='mb-3 font-blue'
              />
              <h3 className='fw-bold'>Keine Kreditkarte</h3>
              <p>Diskret und Sicher</p>
            </div>
            <div className='col-md-6 col-lg-4 mt-5'>
              <img
                src={headphones}
                alt='help'
                width='48px'
                className='mb-3 font-blue'
              />
              <h3 className='fw-bold'>Online Support</h3>
              <p>Antwort innerhalb 12 Stunden</p>
            </div>
            <div className='col-md-6 col-lg-4 mt-5'>
              <img
                src={lock}
                alt='help'
                width='48px'
                className='mb-3 font-blue'
              />
              <h3 className='fw-bold'>Deutscher Datenschutz</h3>
              <p>Deutscher Server und Datenschutz</p>
            </div>
          </div>
          <Link to='/alle-produkte' className={`${classes.fitContent}`}>
            <MDBBtn className={`${classes.btnYellow} btn mx-auto my-5`}>
              Jetzt kaufen
            </MDBBtn>
          </Link>

          <div className='row'>
            <div className='d-flex flex-wrap justify-content-center mt-md-3'>
              <img src={paysafe} className={classes.voucher} alt='paysafe' />
              <img src={google} className={classes.voucher} alt='google' />
              <img
                src={playStation}
                className={classes.voucher}
                alt='playstation'
              />
              <img src={xbox} className={classes.voucher} alt='xbox' />
              <img src={steam} className={classes.voucher} alt='steam' />
              <img src={zalando} className={classes.voucher} alt='zalando' />
              <img src={amazon} className={classes.voucher} alt='amazon' />
              <img src={netflix} className={classes.voucher} alt='netflix' />
              <img src={tmobile} className={classes.voucher} alt='tmobile' />
              <img src={vodafone} className={classes.voucher} alt='vodafone' />
              <img src={congstar} className={classes.voucher} alt='congstar' />
              <img src={blau} className={classes.voucher} alt='blau' />
              <img src={bigpoint} className={classes.voucher} alt='bigpoint' />
              <img
                src={bildmobil}
                className={classes.voucher}
                alt='bildmobil'
              />
              <img src={alditalk} className={classes.voucher} alt='alditalk' />
              <img src={fonic} className={classes.voucher} alt='fonic' />
              <img src={lebara} className={classes.voucher} alt='lebara' />
              <img src={eplus} className={classes.voucher} alt='eplus' />
              <img src={deezer} className={classes.voucher} alt='deezer' />
              <img
                src={klarmobil}
                className={classes.voucher}
                alt='klarmobil'
              />
              <img src={spotify} className={classes.voucher} alt='spotify' />
              <img src={turkcell} className={classes.voucher} alt='turkcell' />
              <img src={yourfone} className={classes.voucher} alt='yourfone' />
              <img src={nintendo} className={classes.voucher} alt='nintendo' />
              <img src={otelo} className={classes.voucher} alt='otelo' />
              <img src={ortel} className={classes.voucher} alt='ortel' />
              <img src={o2} className={classes.voucher} alt='o2' />
              <img
                src={lycamobile}
                className={classes.voucher}
                alt='lycamobile'
              />
              <img src={mobi} className={classes.voucher} alt='mobi' />
            </div>
            <Link to='/alle-produkte' className={`${classes.fitContent}`}>
              <MDBBtn className={`${classes.btnYellow} btn mx-auto my-5`}>
                Jetzt kaufen
              </MDBBtn>
            </Link>
          </div>
        </div>
      </section>

      {/* <section className={`${classes.comingSoon}`}>
        <div className={`container-fluid px-0 text-center`}>
          <h1>
            Coming back <span className={classes.soonBox}>soon</span>
          </h1>
          <p>Mit neuen verbesserten Funktionen</p>
          <h1 className='fw-bold'>Aufgeladene Mastercards</h1>

          <div className='row'>
            <div className='col-lg-5  order-2 order-lg-1'>
              <div className={`text-start ${classes.comingSoonText}`}>
                <p className={`${classes.checkList} d-flex`}>
                  <img src={check} alt='check' className={classes.check} />
                  <span className={`${classes.checkP} d-inline-block`}>
                    Überall wo Mastercard akzeptiert wird sicher bezahlen
                  </span>
                </p>
                <p className={`${classes.checkList} d-flex`}>
                  <img src={check} alt='check' className={classes.check} />
                  <span className={`${classes.checkP} d-inline-block`}>
                    Inkl. Kartennummer, CVC Code und gewähltes Guthaben
                  </span>
                </p>
                <p className={`${classes.checkList} d-flex`}>
                  <img src={check} alt='check' className={classes.check} />
                  <span className={`${classes.checkP} d-inline-block`}>
                    Autmatische Kartenlöschung nach 90 Tagen
                  </span>
                </p>
                <p className={`${classes.checkList} d-flex`}>
                  <img src={check} alt='check' className={classes.check} />
                  <span className={`${classes.checkP} d-inline-block`}>
                    Optional 30 Rechnungskauf*
                  </span>
                </p>
                <span className='text-muted small ps-4'>
                  * Bonität vorausgesetzt
                </span>
              </div>
            </div>
            <div
              className={`text-end col-lg-7  order-1 order-lg-2 ${classes.comingSoonImg}`}
            >
              <picture>
                <source media='(min-width:991px)' srcSet={comingSoonDesktop} />
                <img
                  src={comingSoonMobile}
                  alt='Vouchers'
                  className={`img-fluid ${classes.heroImg}`}
                />
              </picture>
            </div>
          </div>
        </div>
      </section> */}

      <section className={`${classes.vouchersSection} pt-0`}>
        <div className='container-xl text-center px-3 px-sm-4 px-xl-0'>
          <h1 className='text-uppercase mb-5 px-1'>
            Beliebte{' '}
            <span className={`${classes.kreduBox} fw-bold`}>Produkte</span>
          </h1>
          <div
            className={`${classes.vouchers} row text-center d-none d-sm-block`}
          >
            <div className='d-flex justify-content-center mb-3'>
              <img src={paysafe50} alt='paysafe' />
              <img src={google50} className={classes.voucher} alt='google' />
              <img
                src={playstation50}
                className={classes.voucher}
                alt='playstation'
              />
              <img src={xbox25} className={classes.voucher} alt='xbox' />
              <img src={steam50} className={classes.voucher} alt='steam' />
            </div>
          </div>
          <div
            className={`${classes.vouchers} row text-center d-block d-sm-none`}
          >
            <img src={paysafe} alt='paysafe' />
            <img src={google} className={classes.voucher} alt='google' />
          </div>
          <div
            className={`${classes.vouchers} row text-center d-block d-sm-none`}
          >
            <img src={playStation} alt='paysafe' />
            <img src={xbox} className={classes.voucher} alt='google' />
          </div>
          <div
            className={`${classes.vouchers} row text-center mb-5 d-block d-sm-none`}
          >
            <img src={steam} alt='paysafe' />
          </div>
          <Link to='/alle-produkte'>
            <MDBBtn
              className={`btn btn-secondary-blue mx-auto btn text-uppercase`}
            >
              Alle Gutscheine
            </MDBBtn>
          </Link>
        </div>
      </section>

      <section
        id='soFunktioniert'
        className={`bg-white text-center ${classes.soFunction}`}
        ref={howItWorksSection}
      >
        <div className='container'>
          <h1 className='mb-4 pb-sm-5'>
            Wie funktioniert
            <span className={`${classes.kreduBox} ps-1 pe-sm-3 pe-2`}>
              KREDU
            </span>
          </h1>
          <div className='row'>
            <div
              className={`${classes.howWidth} col-12 col-sm-6 col-md-3 text-start mt-md-3 px-sm-2`}
            >
              <div className={classes.num}>1</div>
              <picture>
                <source media='(min-width:576px)' srcSet={how1} />
                <img
                  src={how1Mobile}
                  alt='how1'
                  className={`${classes.howImg} img-fluid mt-1`}
                />
              </picture>
              <p className='pt-3'>Einfach online registrieren</p>
            </div>
            <div
              className={`${classes.howWidth} text-start mt-md-3 px-sm-2 col-12 col-sm-6 col-md-3`}
            >
              <div className={classes.num}>2</div>
              <picture>
                <source media='(min-width:576px)' srcSet={how2} />
                <img
                  src={how2Mobile}
                  alt='how2'
                  className={`${classes.howImg} img-fluid mt-1`}
                />
              </picture>
              <p className='pt-3'>Identifizierung in Minuten</p>
            </div>
            <div
              className={`${classes.howWidth} text-start mt-md-3 px-sm-2 col-12 col-sm-6 col-md-3`}
            >
              <div className={classes.num}>3</div>
              <picture>
                <source media='(min-width:576px)' srcSet={how3} />
                <img
                  src={how3Mobile}
                  alt='how3'
                  className={`${classes.howImg} img-fluid mt-1`}
                />
              </picture>
              <p className='pt-3'>Gutschein per E-Mail erhalten</p>
            </div>
            <div
              className={`${classes.howWidth} text-start mt-md-3 px-sm-2 col-12 col-sm-6 col-md-3`}
            >
              <div className={classes.num}>4</div>
              <picture>
                <source media='(min-width:576px)' srcSet={how4} />
                <img
                  src={how4}
                  alt='how4'
                  className={`${classes.howImg} img-fluid mt-1`}
                />
              </picture>
              <p className='pt-3'>Gutschein online einlösbar</p>
            </div>
          </div>
          <Link to='/alle-produkte' className={`${classes.fitContent}`}>
            <MDBBtn className={`${classes.btnYellow} btn mx-auto my-5`}>
              Jetzt kaufen
            </MDBBtn>
          </Link>
        </div>
      </section>

      <section className={`${classes.faq}`} id='faq'>
        <div className='container-lg'>
          <h1 className='text-center text-uppercase pb-4'>
            Häufige <span className={classes.fragenBox}>Fragen</span>
          </h1>
          <div className='row'>
            <div className='col-lg-10 offset-lg-1 col-12'>
              <MDBAccordion flush initialActive={1} className='accordion-flush'>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle='Ich wurde für den Rechnungskauf abgelehnt und verstehe nicht wieso'
                >
                  Wurdest du abgelehnt, bedeutet dies meistens, dass die
                  Kriterien für den Rechnungskauf bzw. die Vorgaben unserer
                  Bonitätsabteilung nicht erfüllt wurden. In den meisten Fällen
                  sind es negative Schufa Einträge oder ein zu geringes
                  Einkommen, die zu einer Ablehnung führen. Du kannst dich dazu
                  direkt an die Schufa wenden und eine Eigenauskunft beantragen.
                  Das kannst du hier tun:{' '}
                  <a
                    className='font-blue d-inline small'
                    href='https://www.meineschufa.de/de/datenkopie'
                  >
                    https://www.meineschufa.de/de/datenkopie
                  </a>
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={2}
                  headerTitle='Ich habe eine negative Schufa. Kann ich trotzdem einen Gutschein erwerben?
                  '
                >
                  Du hast trotzdem die Möglichkeit einen Gutschein per Vorkasse
                  zu erwerben. Den Kauf auf Rechnung können wir dir aber leider
                  nicht zur Verfügung stellen.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle='Wie erhalte ich meine Rechnung bzw. den Gutscheincode?'
                >
                  Nach erfolgreicher Bestellung und Annahme durch uns erhältst
                  du eine E-Mail mit einem PDF-Dokument im Anhang. Auf dieser
                  Rechnung findest du den jeweiligen Gutscheincode.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={4}
                  headerTitle='Ab wann ist der Gutschein gültig?
                  '
                >
                  Sobald du die Rechnung erhalten hast, auf dem der
                  Gutscheincode aufgeführt ist, kannst du diesen auch einlösen.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={5}
                  headerTitle='Wie lange dauert es, bis ich den Gutschein erhalte?
                  '
                >
                  Wir sind stets bemüht, deine Bestellungen schnellstmöglich
                  abzuarbeiten, um kurze Lieferzeiten zu ermöglichen. Die
                  Wartezeit für den Erhalt deiner Bestellung beträgt in der
                  Regel ein paar Minuten, dies kann am Wochenende sowie an
                  Feiertagen jedoch variieren. <br />
                  Gelegentlich kann es zu technischen Verzögerungen kommen und
                  sich dadurch die Auslieferung verspäten. Sollte das der Fall
                  sein so können Sie uns per Email oder Chat kontaktieren.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={6}
                  headerTitle='Was passiert, wenn ich meine Rechnungen nicht bezahle (und auch keine Stundung für die Rechnung erhalten habe)?
                  '
                >
                  Wir bitten dich, deine Rechnungen fristgerecht zu entrichten.
                  Ist dir dies jedoch nicht möglich, kontaktieren uns bitte
                  frühzeitig und wir versuchen eine Lösung zu finden die
                  eventuell dann anfallenden Inkassogebühren so gering wie
                  möglich zu halten. <br />
                  Wenn die Rechnungen jedoch nicht fristgerecht entrichtet
                  werden, müssen wir die überfällige Forderung zur weiteren
                  Betreibung an ein Inkassounternehmen übergeben. Die Abwicklung
                  ist für uns mit einem sehr hohen Aufwand verbunden und für
                  dich verursacht dies weitere Kosten, welche dir direkt von dem
                  Inkassounternehmen in Rechnung gestellt werden.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={8}
                  headerTitle='Kann ich meine Gutscheinbestellung stornieren?
                  '
                >
                  Solange noch keine Rechnung und somit kein Code versendet
                  wurde, kannst du deine Bestellung jederzeit stornieren. Sende
                  uns bitte eine E-Mail mit Angabe der Rechnungsnummer und dem
                  Wunsch auf Stornierung an info@kredu.de. <br />
                  Sollte sich dein Stornierungswunsch mit dem Versand
                  überschneiden, ist eine Stornierung leider nachträglich nicht
                  möglich.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={9}
                  headerTitle='Mein Gutschein ist ungültig. Was kann ich tun?
                  '
                >
                  Wir verkaufen ausschließlich neue Gutscheine. Trotz aller
                  Prüfung kann es natürlich zu Fehlern kommen. Bitte wende dich
                  in diesem Fall per E-Mail an info@kredu.de und nenne uns
                  unbedingt die Rechnungsnummer sowie die Seriennummer des
                  Gutscheins.
                </MDBAccordionItem>
              </MDBAccordion>
            </div>
          </div>
          <div className='text-center mt-5'>
            <Link to='/faq' className='mx-auto d-inline-block'>
              <MDBBtn className='btn-secondary-blue text-uppercase'>
                Mehr Fragen?
              </MDBBtn>
            </Link>
          </div>
        </div>
      </section>

      <section className={`${classes.infoKredu} bg-white`}>
        <div className='container text-center'>
          <h1>
            Kunden vertrauen
            <span className={`${classes.kreduBox} pb-5 ps-1 pe-3`}>KREDU</span>
          </h1>
          <div className='row my-5'>
            <div className='col-12 col-md-6 ps-md-5'>
              <h1 className={`${classes.numbers} font-blue fw-bold`}>
                +170.000
              </h1>
              <p>Registrierte Kunden</p>
            </div>
            <div className='col-12 col-md-6 pe-md-5'>
              <h1 className={`${classes.numbers} font-blue fw-bold`}>+93%</h1>
              <p>Kundenzufriedenheit</p>
            </div>
          </div>
          <div className={classes.line} />
          <p className='my-5 mx-xl-5 p-xl-5'>
            Profitiere beim Einkaufen von einer datensicheren Infrastruktur und
            seriösen Partnerschaften. Wir arbeiten mit starken und
            vertrauensvollen Unternehmen zusammen. Diese unterstützen uns dabei,
            dir eine schnelle und sichere Aufbuchung deines Guthabens zu
            garantieren.
          </p>
          <div className='d-flex px-lg-5 justify-content-center justify-content-between'>
            <img
              src={schufa}
              className={`${classes.logoImg} img-fluid px-2`}
              alt='schufa'
            />
            <img
              src={microsoft}
              className={`${classes.logoImg} img-fluid px-2`}
              alt='microsoft'
            />
            <img
              src={sofort}
              className={`${classes.logoImg} img-fluid px-2`}
              alt='sofort'
            />
            <img
              src={webId}
              className={`${classes.logoImg} img-fluid px-2`}
              alt='webId'
            />
          </div>
        </div>
      </section>
    </Fragment>
  )
}

export default HomepageMain
